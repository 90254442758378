import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu: any = true;
  public showAdminMenu: any = true;

  // public baseUrl: string = "https://mergautam.com:2141/api/";
  // public baseUrl = 'https://us-central1-aicasindia-in.cloudfunctions.net/app/api/';
  public baseUrl = 'https://api.aicasindia.in/api/';
  // public baseUrl = 'http://localhost:2222/api/';
  constructor(private afs: AngularFirestore, public http: HttpClient) { }

  // saveFranchiseInquiry(data) {
  //   return this.afs.collection('franchisesInquiry').doc().set(data);
  //  }
  //  saveStudentInquiry(data) {
  //   return this.afs.collection('studentsInquiry').doc().set(data);
  //  }
  //!node js api
  saveFranchiseInquiry(data) {
    return this.http.post(this.baseUrl + 'inquiry_franchises/add_inquiry_franchises', data);
  }
  saveStudentInquiry(data) {
    return this.http.post(this.baseUrl + 'inquiry_students/add_inquiry_students', data);
  }
  //!get banner
  getBanner() {
    return this.http.get(this.baseUrl + 'banner/get_banner');
  }
  //!get gallery
  getGallery() {
    return this.http.get(this.baseUrl + 'gallery/get_galleryImage');
  }

  //! get Youtube links
  getLink() {
    return this.http.get(this.baseUrl + 'youtube_links/getLinks');
  }

  getQuestion(language) {
    return this.http.post(this.baseUrl + 'questions/get_question', language);
  }

  saveExamAnswers(data) {
    return this.http.post(this.baseUrl + 'answers/add_answer', data);
  }
  addStudent(data) {
    return this.http.post(this.baseUrl + 'students/add_student_exam_detail', data);
  }
  // saveExamAnswers(data) {
  //   return this.http.post(this.baseUrl + "answers/add_answers", data);
  // }

  saveExamWithStatusUpdate(data) {
    return this.http.post(this.baseUrl + 'students/update', data);
  }

  getAllSubject() {
    return this.http.get(this.baseUrl + 'subject/get_all_subjects');
  }
}
